import ROUTES from "./constants/Routes";

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const decodeMaskedMobileNumber = (
  middleFiveDigits,
  maskedMobileNumber
) => {
  // Check if the provided middleFiveDigits and maskedMobileNumber are valid
  if (middleFiveDigits.length !== 5 || maskedMobileNumber.length !== 10) {
    throw new Error(
      "Invalid input. middleFiveDigits should be 5 digits, and maskedMobileNumber should be 10 digits."
    );
  }

  // Replace 'XXXXX' in the maskedMobileNumber with the middleFiveDigits
  return maskedMobileNumber.replace("XXXXX", middleFiveDigits);
};

export const checkAndRedirect = (
  isBureau,
  sessionData,
  userProducts,
  product,
  navigate,
  urlParams
) => {
  try {
    const paramsObject = {};
    urlParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    if (product) {
      const productId = product.id;
      const userProduct = userProducts.find(
        (product) => product.product_id === productId
      );
      if (userProduct) {
        if (userProduct.is_converted) {
          // navigate to dashbord with prduct url
          navigate(`/${product.url_suffix}/dashboard`, { state: paramsObject });
          return;
        }
      }
      navigate(`/${product.url_suffix}/questions`, { state: paramsObject });


    } else {
      navigate(ROUTES.DASHBOARD, {
        state: paramsObject,
      });
    }
  } catch (error) {
    console.log("ERROR routing", error);
  }
  // if (getSessionData?.isSuccess) {
  // 	navigate(ROUTES.DASHBOARD, { state: urlParams });
  // } else if (isBureau === false && isObjectEmpty(getSessionData?.quesanws)) {
  // 	// Redirect to the PAN screen if isPan is true and user haven't submitted any of the answer before
  // 	navigate(ROUTES.PAN, { state: urlParams });
  // } else if (isBureau === false && !isObjectEmpty(getSessionData?.quesanws)) {
  // 	// Redirect to the PAN Question flow if isPan is true and user has submitted at least one of the answer
  // 	navigate(ROUTES.PAN_QUESTIONS, { state: urlParams });
  // } else if (isBureau === true) {
  // 	// Redirect to the QUESTIONS screen when isPan is false
  // 	navigate(ROUTES.QUESTIONS, { state: urlParams });
  // }
};

export function formatCash(amount) {
  if (amount !== null && amount !== "") {
    amount = parseFloat(amount);

    function truncateFloat(number, places) {
      return Math.round(number * Math.pow(10, places)) / Math.pow(10, places);
    }

    if (amount < 1e3) {
      return amount;
    }
    if (1e3 <= amount && amount < 1e5) {
      return "Rs " + truncateFloat((amount / 1e5) * 100, 1) + " Thousand";
    }
    if (1e5 <= amount && amount < 1e7) {
      return "Rs " + truncateFloat((amount / 1e7) * 100, 1) + " Lakh";
    }
    if (amount >= 1e7) {
      return "Rs " + truncateFloat(amount / 1e7, 1) + " Cr";
    }
  } else {
    return 0;
  }
}

export function maskString(str) {
  // Check if the input string is empty or null
  if (!str || str.length === 0) {
    return "";
  }

  // Get the length of the string
  const len = str.length;

  // Extract the last four characters of the string
  const lastFour = str.substring(len - 4);

  // Replace all characters except the last four with X's
  const masked = "X".repeat(len - 4) + lastFour;

  return masked;
}

export function numberToIndianCurrencyText(num) {
  if (isNaN(num)) return "Invalid number";

  // Function to convert number to Indian currency text
  function convertToIndianCurrencyText(number) {
    var ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    var tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    var teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    function convertToWords(n) {
      var str = "";
      if (n >= 100) {
        str += ones[Math.floor(n / 100)] + " Hundred ";
        n %= 100;
      }
      if (n > 19) {
        str += tens[Math.floor(n / 10)] + " " + ones[n % 10];
      } else if (n > 9) {
        str += teens[n - 10];
      } else {
        str += ones[n];
      }
      return str;
    }

    var crore = Math.floor(number / 10000000);
    var lakh = Math.floor((number % 10000000) / 100000);
    var thousand = Math.floor((number % 100000) / 1000);
    var remainder = number % 1000;

    var result = "";

    if (crore > 0) {
      result += convertToWords(crore) + " Crore ";
    }
    if (lakh > 0) {
      result += convertToWords(lakh) + " Lakh ";
    }
    if (thousand > 0) {
      result += convertToWords(thousand) + " Thousand ";
    }
    if (remainder > 0) {
      result += convertToWords(remainder);
    }

    return result !== "" ? result.trim() + " Rupees" : "";
  }

  return convertToIndianCurrencyText(num);
}

export const addCommas = (value) => {
  // Convert the value to a string
  let stringValue = value.toString();

  // Add commas to the integer part
  stringValue = stringValue.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

  // Return the formatted integer part
  return stringValue;
};

export const removeCommas = (value) => {
  return value.replace(/,/g, "");
};

export const getCorpusRequiredAge = (childAge) => {
  var childAgeInt = parseInt(childAge);
  var craValues = {
    8: 18,
    9: 25,
    10: 25,
    11: 25,
    12: 25,
    13: 25,
    14: 25,
    15: 25,
    16: 26,
    17: 27,
  };

  return craValues[childAgeInt] || 18;
};


export const addStarsOnEmail = (email) => {
  try {
    if (email?.length > 0) {
      const atIndex = email.indexOf("@");
      const namePart = email.slice(0, atIndex);
      const domainPart = email.slice(atIndex + 1);

      const maskedName = namePart[0] + "*".repeat(namePart.length - 1);
      const domainIndex = domainPart.indexOf(".");
      const maskedDomain =
        domainPart[0] +
        "*".repeat(domainIndex - 1) +
        domainPart.slice(domainIndex);

      email = maskedName + "@" + maskedDomain;
    }

    return email;
  } catch (err) {
    return email;
  }
};

export function reverseDigits(num) {
  // Convert the number to a string and reverse it
  const reversedStr = num.toString().split('').reverse().join('');
  // Convert the reversed string back to a number
  return parseInt(reversedStr, 10);
}

export const createApiRequestWithCsrf = (url, method = 'POST') => ({
  query: (payload) => {

    // Add CSRF token to the payload
    return {
      url,  // Use the provided URL
      method,  // Use the provided method (default: POST)
      credentials: 'include',
      body: {
        ...payload,  // Spread the existing payload
      },
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };
  },
  invalidatesTags: ['Post'],
});

/**
* Get the value of a query parameter from URLSearchParams.
* @param {URLSearchParams} params - The URLSearchParams object containing query parameters.
* @param {string} paramName - The name of the query parameter to retrieve.
* @return {string|null} - The value of the query parameter or null if not found.
*/
export function getQueryParamValue(params, paramName) {
  // Get the value of the query parameter
  const value = params.get(paramName);
  return value;
}