import React from "react";
import logoExperian from "../../../../assets/images/logo-experian.png";
import WhatsAppButton from "../../../Common/WhatsAppButton";

// eslint-disable-next-line react/prop-types
const DashboardFooter = ({ creditScore }) => {
	return (
		<>
			<div className="ml-5">
				<p className="text-md  font-bold text-xl text-indigo-950 text-opacity-70">
					Your Credit Score is <strong>{creditScore}</strong>
				</p>
				<p className="text-md mt-4 lg:mt-1 text-gray-500">
					This score doesn&apos;t have any co-relation with your
					Insurance Health Report
				</p>

				<p className="text-md mt-4 lg:mt-1 text-gray-500">
					Powered by&nbsp;
					<a
						href={"https://www.experian.com/"}
						target={"_blank"}
						rel="noreferrer"
					>
						<img
							src={logoExperian}
							alt={"Experian"}
							className={"inline w-32"}
						/>
					</a>
				</p>

				<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mt-4 lg:mt-1">
					<p className="text-xs mt-4 lg:mt-1 text-gray-500">
						Report any issue with the results&nbsp;
						<a
							href={
								"https://consumer.experian.in/ECSINDIA-DCE/?isDCEFlow=true"
							}
							target={"_blank"}
							rel="noreferrer"
							className={"text-blue-800"}
						>
							here
						</a>
					</p>
					<div className="mt-2 lg:mt-0 flex justify-center lg:justify-end w-full lg:w-auto">
						<WhatsAppButton />
					</div>
		  		</div>
			</div>
		</>
	);
};

export default DashboardFooter;
