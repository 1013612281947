import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/config';
import { createApiRequestWithCsrf } from '../../utils/common';

export const otpApi = createApi({
    reducerPath: 'otpSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        credentials: 'include',
        prepareHeaders: (headers) => {
            const csrfToken = window?.localStorage.getItem('csrfToken');
            if (csrfToken) {
                headers.set('X-CSRFToken', csrfToken);
            }
            return headers;
        },
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        otpPost: builder.mutation(createApiRequestWithCsrf('/user/check/otp')),
    }),
});

export const { useOtpPostMutation } = otpApi;