import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import noSpam from "../assets/images/no-spam.png";
import useLogin from "../hooks/useLogin";
import { setUserLoginData } from "../store/slices/loginSlice";
import { checkAndRedirect, isObjectEmpty } from "../utils/common";
import STRINGS from "../utils/constants/strings";
import { isNameValid, isPhoneNumberValid } from "../utils/validations";
import { validateName } from "../utils/helpers/questions";
import Spinner from "./Common/Loaders/Spinner";
import Error from "./Error/Error";
import Otp from "./Otp";
import useAuthRedirect from "../hooks/useAuthRedirect";
import { getProductFromUrlSuffix } from "../utils/helpers/products";
import InfoIcon from "../../src/assets/images/infoicon.svg";
import ArrowIcon from "../../src/assets/images/bluearrow.svg";


const Login = () => {
	const navigate = useNavigate();
	let { search, pathname } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const [isErrorName, setIsErrorName] = useState('');
	const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState('');
	const [isTermsChecked, setIsTermsChecked] = useState(true);
	const [isConsentChecked, setIsConsentChecked] = useState(false);
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const { product_url } = useParams();
	let params = new URLSearchParams(search);
	const sessionData = useSelector((state) => state.session.sessionData);
	const userProducts = useSelector((state) => state.products.userProducts);
	const currentProduct = useSelector((state) => state.products.currentProduct);
	const products = useSelector((state) => state.products.products);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	useAuthRedirect(sessionData, pathname, products, product_url, userProducts, currentProduct, params);

	const getSessionData =
		useSelector((state) => state?.session?.sessionData) || {};

	useEffect(() => {
		if (!isObjectEmpty(getSessionData) && userProducts && products) {
			let product = getProductFromUrlSuffix(products, product_url);
			checkAndRedirect(
				getSessionData?.isburea,
				getSessionData,
				userProducts,
				product,
				navigate,
				params
			);
		}
	}, [getSessionData, userProducts]);

	useEffect(() => {
		let fullName = searchParams.get('name');
		let phnNo = searchParams.get('mobile'); // Corrected to match the query parameter key
		if (fullName) {
			setName(fullName); // Set the state for name
		}
		if (phnNo) {
			setPhoneNumber(phnNo); // Set the state for phoneNumber
		}
	}, []);

	const { loginDone, isLoading, performLogin, setLoginDone, error } = useLogin();

	const handleForm = (event) => {
		event.preventDefault();
		const errorNameMessage = validateName(name);
		const errorPhoneNumberMessage = isPhoneNumberValid(phoneNumber);
		setIsErrorName(errorNameMessage === true ? '' : errorNameMessage);
		setIsErrorPhoneNumber(errorPhoneNumberMessage || '');

		if (
			errorNameMessage === true &&
			!errorPhoneNumberMessage &&
			isTermsChecked &&
			isConsentChecked
		) {
			dispatch(setUserLoginData({ name, phoneNumber })); // Save name and phoneNumber in the redux store
			performLogin(name, phoneNumber);
		}
	};


	// Calculate whether the button should be disabled or not
	const isButtonDisabled =
		!name ||
		!phoneNumber ||
		isErrorName !== '' || // Check if there is an error message for the name
		!!isErrorPhoneNumber || // Check if there is an error message for the phone number
		!isTermsChecked ||
		!isConsentChecked;

	const _addBtnDisableClass = isButtonDisabled ? 'disabled:opacity-25' : '';

	// Handle phone number input change
	const handlePhoneNumberChange = (event) => {
		setPhoneNumber(event.target.value);
		// Clear the phone number error message when the input changes
		setIsErrorPhoneNumber('');
	};

	const handleNameChange = (event) => {
		const newName = event.target.value;
		setName(newName);
		const errorNameMessage = validateName(newName);
		setIsErrorName(errorNameMessage === true ? '' : errorNameMessage);
	};

	const dynamicStrings = STRINGS[product_url] || STRINGS.common; // Fallback to default if urlSuffix is not found

	const toggleDropdown = () => {
		setIsDropdownVisible(!isDropdownVisible);
	};

	return (
		<>
			{isLoading && <Spinner />}
			{loginDone && <Otp resetLogin={setLoginDone} productUrl={product_url} />}
			{error && (
				<section className="flex w-full justify-center">
					<div className="w-full lg:w-6/12">
						<Error />
					</div>
					{/* <div className="hidden lg:block">
			<img src={loginImg} alt="Login" />
		  </div> */}
				</section>
			)}
			{!loginDone && !error && (
				<>
					<section className="flex flex-col justify-between mt-2 lg:pl-10 py-7 items-start lg:items-center hidden md:block">
						<h1 className="w-full font-bold text-[#020064] text-xl lg:text-3xl text-center lg:whitespace-nowrap md:text-lg">
							{dynamicStrings.title}
						</h1>
						<h2 className=" w-full font-bold text-[#020064] text-xl lg:text-3xl text-center mt-2">
							{dynamicStrings.title_text_2}
						</h2>
					</section>

					<section className="flex flex-col-reverse lg:flex-row justify-center mt-6 lg:mt-8 ">
						{/* Form section */}
						<div className="w-full bg-white p-4 lg:w-3/5 lg:pr-8 order-1 lg:order-2 flex items-center border rounded-md shadow-lg">
							<div className="lg:pl-10 pt-2">
								<p className="font-bold text-[#020064] text-md md:text-xl lg:text-xl mb-4">
									{dynamicStrings.form_title}
								</p>
								<p className="font-semibold text-[#020064] text-xs sm:text-sm md:text-sm lg:text-sm mb-4">
									{dynamicStrings.form_sub_title}
								</p>

								<form onSubmit={handleForm}>
									<div className="mb-4">
										<label htmlFor="name" className="text-xs md:text-sm font-semibold block mb-2">
											{STRINGS.common.name}{" "}
											<span className="text-gray-400 text-xs md:text-sm">
												{STRINGS.common.as_per_pan}
											</span>
										</label>
										<input
											placeholder={STRINGS.common.name_field_placeholder}
											type="text"
											id="name"
											className="border border-gray-500 w-full rounded-lg px-2 py-4 text-xs md:text-sm"
											onChange={handleNameChange}
											autoComplete={"off"}
											value={name}
										/>
										<p className={"absolute text-red text-red-600 text-xs md:text-sm"}>
											{isErrorName}
										</p>
									</div>

									<div className="relative mb-4 mt-6">
										<label htmlFor="mobile" className="text-xs md:text-sm font-semibold block mb-2">
											{STRINGS.common.mobile}{" "}
										</label>
										<div className="relative">
											<div className="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-600 text-xs md:text-sm">
												+91
											</div>
											<input
												placeholder={STRINGS.common.mobile_field_placeholder}
												type="text"
												id="mobile"
												className="border border-gray-500 w-full rounded-lg pl-12 pr-4 py-4 text-xs md:text-sm"
												onChange={handlePhoneNumberChange}
												autoComplete={"off"}
												value={phoneNumber}
											/>
										</div>
										<p className="absolute text-red-600 text-sm">
											{isErrorPhoneNumber}
										</p>
									</div>


									<p className="font-semibold text-black text-xs md:text-sm lg:text-sm mb-4">
										{STRINGS.common.spam_warning}
									</p>

									<div className="flex items-start mb-3">
										<input
											type="checkbox"
											id="terms"
											className="text-sm md:text-lg mt-1 rounded-md w-4"
											checked={isTermsChecked}
											onChange={() => setIsTermsChecked(!isTermsChecked)}
										/>
										<label htmlFor="terms" className="text-xs ml-2 cursor-pointer">
											{STRINGS.common.terms_conditions}
										</label>
									</div>

									<div className="flex items-start mb-1 mt-1">
										<input
											type="checkbox"
											id="consent"
											className="text-lg mt-1 rounded-md w-4"
											checked={isConsentChecked}
											onChange={() => setIsConsentChecked(!isConsentChecked)}
										/>
										<label htmlFor="consent" className="text-xs ml-2 cursor-pointer w-11/12">
											{STRINGS.common.user_consent}
										</label>
									</div>
									<div className="flex justify-center">
										<p className="text-[9px] pt-4 mb-2 cursor-pointer font-bold">
											{STRINGS.common.credit_score_warning}
										</p>
									</div>

									<button
										type="submit"
										className={`bg-[#F4CD2A] block rounded-md py-3 lg:px-20 mt-5 w-1/2 lg:w-auto ${_addBtnDisableClass}`}
										disabled={isButtonDisabled}
										style={{ margin: "0 auto" }}
									>
										{STRINGS.common.get_otp}
									</button>

									<div className="max-w-2xl mx-auto mt-4">
										<div className="bg-white border border-gray-300 rounded-lg shadow-lg">
											{/* Dropdown Toggle Button */}
											<button
												type="button"
												className="flex items-center w-full px-4 py-3 text-black focus:outline-none"
												onClick={toggleDropdown}
											>
												<img src={InfoIcon} alt="Info Icon" className="mr-2 w-5 h-5" />
												<span className="text-xs flex-1 text-left font-semibold">
													{STRINGS.common.dropdown_header}
												</span>
												<img
													src={ArrowIcon}
													alt="Arrow Icon"
													className={`ml-2 w-5 h-5 transform transition-transform ${isDropdownVisible ? 'rotate-180' : 'rotate-0'}`}
												/>
											</button>

											{/* Dropdown Section */}
											{isDropdownVisible && (
												<div className="px-4 pb-4 font-semibold">
													<p className="mt-2 text-xs text-black">
														{STRINGS.common.dropdown_point_1}
													</p>
													<p className="mt-2 text-xs text-black">
														{STRINGS.common.dropdown_point_2}
													</p>
												</div>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>


					</section>
				</>
			)}
		</>
	);
}

export default Login;
