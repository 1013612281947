export function hasUserPurchasedProduct(products, userProducts, urlSuffix) {
    // Find the product ID corresponding to the given urlSuffix
    const productId = products.find(product => product.url_suffix === urlSuffix)?.id;

    if (productId) {
        // Check if there's a userProduct entry for the given product ID
        const userProduct = userProducts.find(product => product.product_id === productId);

        if (userProduct) {
            // If order_id is not null and is_converted is true, the user has purchased the product
            if (userProduct.is_converted) {
                return true;
            }
        }
    }

    return false;
}

export function getProductFromUrlSuffix(products, urlSuffix) {
    if (!products || (products && products.length) === 0) return null;
    // Find the product object with the matching url_suffix
    const product = products.find(product => product.url_suffix === 'ihr');

    // If product is found, return its ID, otherwise return null
    return product ?? null;
}

export function getProductFromId(products, id) {
    if (products && products.length === 0) return null;
    // Find the product object with the matching url_suffix
    const product = products.find(product => product.id === id);

    // If product is found, return its ID, otherwise return null
    return product ?? null;
}


export function isUserInrestedinProduct(currentProduct, userProducts) {
    // Find the product ID corresponding to the given urlSuffix
    const productId = currentProduct.id;

    if (productId) {
        // Check if there's a userProduct entry for the given product ID
        const userProduct = userProducts.find(product => product.product_id === productId);

        // If userProduct is found, return true, otherwise return false
        return !!userProduct;
    }

    return false;
}

export const isProductisValid = (allProducts, product_url) => {
    if (product_url && allProducts) {
        return allProducts.some(item => item.url_suffix === product_url);
    }
}