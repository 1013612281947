// useLogin.js
import { useEffect, useState } from 'react';
import { useLoginPostMutation } from '../store/services/login';
import { setLoginData } from '../store/slices/loginSlice';
import { useDispatch } from 'react-redux';

const useLogin = () => {
    const [loginDone, setLoginDone] = useState(false);

    // Use the useLoginPostMutation hook
    const [loginPost, { data, error, isLoading }] = useLoginPostMutation();

    const dispatch = useDispatch();

    useEffect(() => {
        // You can handle the data and error from the API call here
        if (data) {
            // Handle successful response data
            setLoginDone(true);
            dispatch(setLoginData(data));
        }
        if (error) {
            setLoginDone(false);
            // Handle error
            console.log(error);
        }
    }, [data, error, isLoading, dispatch]);

    const performLogin = async (name, phoneNumber) => {
        try {
            // Call the loginPost mutation
            await loginPost({
                name: name,
                mobile: phoneNumber
            });
        } catch (error) {
            // Handle error if necessary
            console.error('Error during login:', error);
        }
    };

    return { loginDone, isLoading, error, performLogin, setLoginDone };
};

export default useLogin;
