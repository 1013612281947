import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/config';
import { createApiRequestWithCsrf } from '../../utils/common';

export const loginApi = createApi({
    reducerPath: 'loginSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl,
        credentials: 'include',
        prepareHeaders: (headers) => {
            // Add Basic Authentication header
            // headers.set('Authorization', 'Basic ' + btoa('romil.sarna@gmail.com:romil123'));
            headers.set(
                "Authorization",
                `${window?.localStorage.getItem("userid")}`
            );
            const csrfToken = window?.localStorage.getItem('csrfToken');
            if (csrfToken) {
                headers.set('X-CSRFToken', csrfToken);
            }

            return headers;
        },
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        loginPost: builder.mutation(createApiRequestWithCsrf('/user/login')),
    }),
});

export const { useLoginPostMutation } = loginApi;