import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/config";
import { createApiRequestWithCsrf } from "../../utils/common";

export const dashboardApi = createApi({
	reducerPath: "dashboardSlice",
	baseQuery: fetchBaseQuery({
		baseUrl: getBaseUrl,
		credentials: 'include',
		prepareHeaders: (headers) => {
			// Add Basic Authentication header
			headers.set(
				"Authorization",
				`${window?.localStorage.getItem("userid")}`
			);
			const csrfToken = window?.localStorage.getItem('csrfToken');
			if (csrfToken) {
				headers.set('X-CSRFToken', csrfToken);
			}
			return headers;
		},
	}),
	tagTypes: ["Post"],
	endpoints: (builder) => ({
		dashboardPost: builder.mutation(createApiRequestWithCsrf("/user/dashboard")),
	}),
});

export const { useDashboardPostMutation } = dashboardApi;
