export const screensConfig = [
  {
    id: 'personalDetails',
    questions: ['dob', 'gender'], // Date of birth and gender together
  },
  {
    id: 'addressDetails',
    questions: ['address', 'pincode'], // Pincode and address together
  },
  {
    id: 'immediateFamilyDetails',
    questions: ['family'],
  },
  {
    id: 'numChildrenAndAges',
    questions: ['no_of_children'],
    conditional: {
      dependsOn: 'family',
      condition: (value) => value.includes('children'),
    },
  },
  {
    id: 'retirementDetails',
    questions: ['retirement_age'],
  },
  {
    id: 'incomeDetails',
    questions: ['annual_income'],
  },
  {
    id: 'emailDetails',
    questions: ['email'],
  },
  {
    id: 'healthInsuranceDetails',
    questions: ['health_insurance'],
  },
  {
    id: 'insuranceDetails',
    questions: ['health_insurance_insurer_name', 'health_insurance_sum_insured', 'health_insurance_product_id'],
    conditional: {
      dependsOn: 'purchased_by',
      condition: (value) => value === 'you' || value === 'both',
    },
  },
  {
    id: 'planValueDetails',
    questions: ['health_insurance_sum_insured'],
    conditional: {
      dependsOn: ['employer_provided_insurance', 'purchased_by'],
      condition: (values) => {
        return values.employer_provided_insurance === 'yes' || values.purchased_by === 'employer';
      },
    },
  },
];
