import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./components/Common/Footer";
import Header from "./components/Common/Header";
import Spinner from "./components/Common/Loaders/Spinner";
import { auth, getBearerToken } from "./firebase";
import { findDynamicRoute } from "./hooks/useAuthRedirect";
import { useGetProductsQuery } from "./store/services/products";
import { useGetSessionMutation } from "./store/services/session";
import { useApi } from "./store/services/useApi";
import { useGetUserProductsQuery } from "./store/services/userProducts";
import { removeCurrentProduct, setAppProducts, setCurrentProduct, setUserProducts } from "./store/slices/productsSlice";
import { setSessionData } from "./store/slices/sessionSlice";
import ROUTES from "./utils/constants/Routes";
import { refreshToken } from "./utils/helpers/authentication";
import { getProductFromUrlSuffix, isUserInrestedinProduct } from "./utils/helpers/products";
import { getQueryParamValue } from "./utils/common";
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
// ReactGA.pageview(window.location.pathname + window.location.search);

const App = (props) => {
	const navigate = useNavigate();
	let { search, pathname } = useLocation();
	let params = new URLSearchParams(search);
	const { product_url } = useParams();
	const products = useSelector((state) => state.products.products);
	const userProducts = useSelector((state) => state.products.userProducts);
	const { data: allProductsResp } = useGetProductsQuery(undefined, { skip: products });
	const currentProduct = useSelector((state) => state.products.currentProduct);


	const [isGetSessionLoading, setIsGetSessionLoading] = useState(true);
	// Use the useGetSessionMutation hook
	const [getSession, { data: getSessionData }] = useGetSessionMutation();
	const dispatch = useDispatch();

	const getSessionResp = getSessionData;
	const otpData = useSelector((state) => state?.otp?.otpData);

	const { data: userProductsResp } = useGetUserProductsQuery(undefined, { skip: !getSessionResp || userProducts });
	const { makeCall: fetchCsrfToken, data: csrfData, error: csrfError } = useApi('GET', '/csrf-token');

	useEffect(() => {
		if (allProductsResp) {
			dispatch(setAppProducts(allProductsResp));
			if (product_url) {
				let product = getProductFromUrlSuffix(allProductsResp.products, product_url);
				if (product) {
					dispatch(setCurrentProduct(product));
					checkAuth();
				} else {
					setIsGetSessionLoading(false)
					navigate(ROUTES.NOT_FOUND);
				}
			} else {
				dispatch(removeCurrentProduct());
				checkAuth();
			}
		}


		// return () => { setIsGetSessionLoading(false) };
	}, [allProductsResp]);

	const getUtmTags = (params) => {
		const utmParams = {};
		for (const [key, value] of params.entries()) {
			if (key.startsWith('utm')) {
				utmParams[key] = value;
			}
		}
		const queryString = new URLSearchParams(utmParams).toString();

		return queryString;
	}


	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
		ReactGA.send({
			hitType: "pageview",
			page: window.location.pathname + window.location.search,
		});

		let referralCode = getQueryParamValue(params, "referral_code");
		let utms = getUtmTags(params);
		let referrerUrl = document.referrer;
		const currentUrl = window.location.href;
		if (referrerUrl && referrerUrl === currentUrl) {
			referrerUrl = ''
		}
		if (referralCode || referrerUrl || utms) {
			if (referralCode) {
				referralCode = referralCode.split('?')[0];
			}
			localStorage.setItem("referralCode", referralCode);
			localStorage.setItem("referred_url", referrerUrl);
			localStorage.setItem("utmTags", utms);
		}

		let Lhr = params.get("Lhr");
		if (Lhr) {
			// Store Lhr flag in local storage
			Lhr = Lhr.split('?')[0];
			localStorage.setItem("Lhr", Lhr);
		}

	}, []);

	useEffect(() => {
		fetchCsrfToken();
	}, [])

	useEffect(() => {
		if (csrfData) {
			window.localStorage.removeItem('csrfToken');
			window.localStorage.setItem('csrfToken', csrfData.csrfToken); // Store the token in local storage
		}
	}, [csrfData])



	const redirectToLoginPage = () => {
		const dynamicRoute = findDynamicRoute(pathname);
		if (dynamicRoute.requiredAuth) {
			if (product_url) {
				navigate(`/${product_url}/login`);
			} else {
				navigate(ROUTES.LOGIN);
			}
		}
	}

	const checkAuth = () => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				// Once the user is logged in, fetch API data using their ID token
				user.getIdToken()
					.then((idToken) => {
						window.localStorage.setItem("userid", idToken);
						fetchData();
					})
					.catch((error) => {
						console.error("Error getting ID token:", error);
					});
			} else {
				setIsGetSessionLoading(false);
				redirectToLoginPage();
				window.localStorage.removeItem("userid");
			}
		});

		// Cleanup function (optional)
		return () => unsubscribe();
	};

	const {
		makeCall: updateUserProducts,
	} = useApi("POST", "/user/productDetails");


	useEffect(() => {
		if (userProductsResp) {
			dispatch(setUserProducts(userProductsResp));
			if (currentProduct && !isUserInrestedinProduct(currentProduct, userProductsResp)) {
				//make an entry in userProducts
				updateUserProducts({ product_id: currentProduct.id });
			}
		}
	}, [userProductsResp]);

	const fetchData = async () => {
		try {
			setIsGetSessionLoading(true);
			const { data, error } = await getSession({});
			if (data) {
				setIsGetSessionLoading(false);
				dispatch(setSessionData(data));
				ReactGA.gtag("set", "user_properties", {
					name: data.fname,
				});
			}
			if (error) {
				setIsGetSessionLoading(false);
				if (error.status === 401) {
					window.localStorage.removeItem("userid");
					let token = await refreshToken();
					if (token) {
						window.localStorage.setItem("userid", token);
						fetchData();
					}
				}
			}
		} catch (error) {
			setIsGetSessionLoading(false);
			// Handle any errors here
			console.error("Error fetching session data", error);
		}
	};

	const extractTokenAndFetchData = async (data) => {
		const bearerToken = await getBearerToken(data.token); // Await the result of getBearerToken
		localStorage.setItem("userid", bearerToken);
		fetchData();
	};

	useEffect(() => {
		if (otpData && (getSessionResp === undefined || getSessionResp === null)) {
			extractTokenAndFetchData(otpData);
			if (otpData.userProducts) {
				dispatch(setUserProducts(otpData.userProducts));
			}
		}
	}, [getSessionResp, otpData]);

	// let isFullScreen = [ROUTES.IHR_DASHBOARD, ROUTES.AIR_AMBULANCE_DETAILS].includes(pathname) || pathname.match(new RegExp(`^/${product_url}/details$`));
	let isFullScreen = ROUTES.IHR_DASHBOARD === pathname || pathname.match(new RegExp(`^/${product_url}$`));

	return (
		<>
			{isGetSessionLoading && <Spinner />}
			<div className={"bg-[#207058]"}>
				<div
					className={`w-full m-auto p-0 ${!isFullScreen ? "lg:px-20 lg:py-10 lg:w-[1255px]" : ""
						}`}
				>
					<Header />
					<main
						className={`bg-[#EFFAFF] rounded mt-0 min-h-[600px] flex flex-col items-center justify-center ${!isFullScreen
							? "lg:mt-14 lg:px-10 px-5 lg:py-14"
							: ""
							}`}
					>
						{!isGetSessionLoading &&
							<Outlet />
						}
					</main>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default App;
