import age from '../../../../assets/images/age-image.png';
import income from '../../../../assets/images/income-img.png';
import pincode from '../../../../assets/images/pincode-image.png';
import dependants from '../../../../assets/images/dependants-img.png';
import children from '../../../../assets/images/children-img.png';
import retire from '../../../../assets/images/retire-img.png';
import health_1 from '../../../../assets/images/health-img.png';
import health_2 from '../../../../assets/images/health-img.png';
import email from '../../../../assets/images/email-image.png';

import { validateAgeForAdults, validateAddress, validateAgeForChildren } from '../../../../utils/helpers/questions';

export const ihrquestionsConfig = [
  {
    id: 'dob',
    type: 'date',
    label: 'Date of Birth',
    title: 'When were you born?',
    image: age,
    description: 'Age impacts risk assessment & insurance need in Insurance health report',
    validation: {
      required: "Date of birth is required",
      validate: (value) => validateAgeForAdults(value) || "Age must be between 18 and 65 years old",
    },
    errorMessage: 'Please enter your date of birth',
  },
  {
    id: 'gender',
    type: 'select',
    label: 'Gender',
    options: [
      { value: '', label: 'Select Gender' },
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
    ],
    validation: {
      required: "Gender is required",
    },
    errorMessage: 'Please select your gender',
    message: 'Your personal details are secure with us. We use it to make your personalized Insurance Health Report',
  },
  {
    id: 'annual_income',
    type: 'select',
    title: 'What is your current annual income?',
    label: 'Amount',
    description: 'Your Income helps us understand financial liability & aspirations',
    message: 'Your personal details are secure with us. We use it to make your personalized Insurance Health Report',
    image: income,
    options: [
      { value: '', label: 'Select Annual Income' },
      { value: '150000', label: '0-3 Lakh' },
      { value: '450000', label: '3-6 Lakh' },
      { value: '800000', label: '6-10 Lakh' },
      { value: '1250000', label: '10-15 Lakh' },
      { value: '2000000', label: '15-25 Lakh' },
      { value: '3500000', label: '25-50 Lakh' },
      { value: '7500000', label: '50+ Lakh' },
    ],
    validation: {
      required: "Income is required",
    },
    errorMessage: 'Please select your annual income',
  },
  {
    id: 'address',
    type: 'text',
    label: 'Address',
    title: 'Where do you live?',
    image: pincode,
    description: 'Pincode helps us to understand quality of your life',
    validation: {
      required: "Address is required",
      validate: (value) => validateAddress(value),
    },

    errorMessage: 'Please enter your address',
  },
  {
    id: 'pincode',
    type: 'numeric',
    label: 'Enter pincode',
    validation: {
      required: "Pincode is required",
      pattern: {
        value: /^[1-9][0-9]{5}$/,
        message: "Invalid pincode",
      },
    },
    errorMessage: 'Please enter a valid pincode',
    message: 'Your personal details are secure with us. We use it to make your personalized Insurance Health Report',
  },
  {
    id: 'family',
    type: 'checkbox',
    title: 'Please choose the individuals that are part of your immediate family',
    label: 'You can select multiple options',
    description: 'Your dependents are crucial for insurance and financial planning',
    image: dependants,
    options: [
      { value: 'spouse', label: 'Spouse' },
      { value: 'parents', label: 'Parents' },
      { value: 'children', label: 'Children' },
      { value: 'none', label: 'None' },
    ],
    validation: {
      required: "Please select at least one option",
      validate: (value) => value.length > 0 || "Please select at least one option",
    },
    errorMessage: 'Please select your immediate family members',
  },
  {
    id: 'no_of_children',
    type: 'radio',
    title: 'How many children do you have?',
    description: 'This guides tailored recommendations based on your family structure',
    image: children,
    options: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3+', label: '3+' },
    ],
    validation: {
      required: "Please select the number of children",
    },
    errorMessage: 'Please select the number of children',
  },
  {
    id: 'youngest_child_age',
    type: 'numeric',
    label: 'What is the age of your youngest child?',
    validation: {
      required: "Please enter your child\'s age",
      min: {
        value: 0,
        message: "Age must be greater than 0",
      },
      max: {
        value: 45,
        message: "Age must be less than 45",
      },
      validate: value => value >= 0 && value <= 45 || "Age must be between 0 and 45",
    },
    errorMessage: 'Please enter a valid age for your child',
  },
  {
    id: 'eldest_child_age',
    type: 'numeric',
    label: 'What is the age of your eldest child?',
    validation: {
      required: "Please enter your first child\'s age",
      min: {
        value: 0,
        message: "Age must be greater than 0",
      },
      max: {
        value: 45,
        message: "Age must be less than 45",
      },
      validate: value => value >= 0 && value <= 45 || "Age must be between 0 and 45",
    },
    errorMessage: 'Please enter a valid age for your first child',
  },
  {
    id: 'retirement_age',
    type: 'numeric',
    title: 'At what age are you planning to retire?',
    label: 'Enter Retirement Age',
    image: retire,
    description: 'Planning for retirement is crucial for financial security in the future',
    validation: {
      required: "Retirement age is required",
      min: {
        value: 40,
        message: "Retirement age must be at least 40",
      },
      max: {
        value: 80,
        message: "Retirement age must be less than 80",
      },
      validate: value => value >= 40 && value <= 80 || "Retirement age must be between 40 and 80",
    },
    errorMessage: 'Please enter a valid retirement age (40-80)',
    message: '85% of users have expressed desire to retire at 60',
  },
  {
    id: 'health_insurance',
    image: health_1,
    type: 'radio',
    title: 'Do you have a Health Insurance?',
    description: 'Your response will help advise you on whether to consider porting your health insurance policy',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    validation: {
      required: "Please select an option",
    },
    errorMessage: 'Please select if you have health insurance',
  },
  {
    id: 'employer_provided_insurance',
    type: 'radio',
    label: 'Is your health insurance provided by your employer?',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    conditional: {
      dependsOn: 'health_insurance',
      condition: (value) => value === 'no',
    },
    validation: {
      required: "Please select an option",
    },
    errorMessage: 'Please select if your insurance is provided by your employer',
  },
  {
    id: 'purchased_by',
    type: 'radio',
    label: 'Your health insurance is purchased by:',
    options: [
      { value: 'you', label: 'You' },
      { value: 'employer', label: 'Your Employer' },
      { value: 'both', label: 'Both' },
    ],
    conditional: {
      dependsOn: 'health_insurance',
      condition: (value) => value === 'yes',
    },
    validation: {
      required: "Please select an option",
    },
    errorMessage: 'Please select who purchased your health insurance',
  },
  {
    id: 'health_insurance_insurer_name',
    description: 'Your response will help advise you on whether to consider porting your health insurance policy',
    image: health_2,
    type: 'text',
    title: "What's your Insurer Name?",
    label: 'Insurer Name',
    validation: {
      required: "Insurer Name is required",
    },
    errorMessage: 'Please enter the insurer name',
  },
  {
    id: 'health_insurance_sum_insured',
    image: health_2,
    description: 'Your response will help advise you on whether to consider porting your health insurance policy',
    type: 'text',
    title: "We want to learn more about you",
    label: 'What is your Sum Insured?*',
    validation: {
      required: "Sum Insured is required",
    },
    errorMessage: 'Please enter the sum insured',
  },
  {
    id: 'health_insurance_product_id',
    type: 'text',
    title: "What's your insurance plan's name?",
    label: 'Plan Name',
    validation: {
      required: "Plan Name is required",
    },
    errorMessage: 'Please enter the plan name',
  },
  {
    id: 'email',
    type: 'text',
    image: email,
    title: 'What is your email id?',
    description: 'To provide updates, policy documents, and important communication',
    validation: {
      required: "Email is required",
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        message: "Invalid email address",
      },
    },
    errorMessage: 'Please enter a valid email address',
    message: 'Your personal details are secure with us. We use it to verify your identity',
  },
];
