import React from 'react';
import whatsappIcon from "../../assets/images/whatsappicon.svg";

const WhatsAppButton = () => {
  const whatsappUrl = "https://wa.me/919216652807";

  return (
    <div className="flex justify-center mt-6">
      <a
        href={whatsappUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#207058] text-white font-bold py-2 px-4 rounded-lg flex items-center space-x-2"
      >
        <span>Need Help? Connect on</span>
        <img src={whatsappIcon} alt="WhatsApp Icon" className="h-6 w-6" />
      </a>
    </div>
  );
};

export default WhatsAppButton;
