import { useState } from "react";
import { getBaseUrl } from "../../utils/config";

// Function to get CSRF token from cookies (if stored in cookies)
const getCsrfTokenFromCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; csrf_token=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

export const useApi = (method, url) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const makeCall = async (payload) => {
        setIsLoading(true);
        try {
            // Retrieve CSRF token from localStorage or cookies
            const csrfToken = window?.localStorage.getItem('csrfToken') || getCsrfTokenFromCookie();
            // Prepare headers for the request
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `${window?.localStorage.getItem('userid')}` // Authorization from localStorage      
            };

            // Conditionally add CSRF token to headers if it exists
            if (csrfToken) {
                headers["X-CSRFToken"] = csrfToken;
            }
            const response = await fetch(getBaseUrl + url, {
                method: method, // GET, POST, PUT, DELETE, etc.
                credentials: 'include', // Ensure cookies are included
                headers,
                body: method !== "GET" ? JSON.stringify(payload) : null, // Only attach body if it's not a GET request
            });

            // Check if response is successful
            if (!response.ok) {
                // Parse error message if available
                let errorText;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const errorData = await response.json();
                    errorText = errorData.error || "An error occurred";
                } else {
                    errorText = await response.text();
                }
                throw new Error(errorText);
            }

            // Handle JSON and other content types
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                const responseData = await response.json();
                setData(responseData);
            } else {
                const responseData = await response.text();
                setData(responseData);
            }
        } catch (error) {
            setError(error.message || "An unknown error occurred");
        } finally {
            setIsLoading(false);
        }
    };

    return { data, isLoading, error, makeCall };
};