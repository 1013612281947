import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/Dashboard";
import DetailsPage from "./components/DetailsPage";
import NotFound from "./components/Error/NotFound";
import Login from "./components/Login";
import Order from "./components/Order";
import OrderRedirect from "./components/Order/OrderRedirect";
import UserDetailsForm from "./components/Products/Common/UserDetailsForm";
import IhrDashboard from "./components/Products/IHR/Dashboard";
import MaskNumber from "./components/Products/IHR/Questions/MaskNumber";
import NonVerifiedUserQuestions from "./components/Products/IHR/Questions/NonVerifiedUserQuestions";
import Pan from "./components/Products/IHR/Questions/Pan";
import Questions from "./components/Products/IHR/Questions/Questions";
import appStore from "./store/store";
import "./styles/index.css";
import ROUTES from "./utils/constants/Routes";
import PremiumEstimate from "./components/Products/SuperTopup/PremiumEstimate";
import IhrQuestions from "./components/Products/IHR/Questions/IHRQuestions";
import ScrollToTop from "./components/Common/ScrollToTop";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);

const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * Required to make sure query params are passed on to login screen.
 * @returns 
 */
const IhrLoginRedirect = () => {
	let { search } = useLocation();
	let navigate = useNavigate()
	useEffect(() => {
		if (search !== '') {
			console.log(`/ihr/login${search}`)
			navigate(`/ihr/login${search}`);
		} else {
			navigate(`/ihr/login`);
		}
	}, [search])
	return null
};

export const ROUTE_PROPERTIES =
	[
		{
			path: ROUTES.NOT_FOUND,
			element: <NotFound />,
		},
		// Common routes		
		{
			path: ROUTES.LOGIN,
			element: <Login />,
		},
		{
			path: ROUTES.DASHBOARD,
			requiredAuth: true,
			element: <Dashboard />,
		},
		// IHR routes will be removed to products
		{
			path: ROUTES.IHR_LOGIN,
			element: <IhrLoginRedirect />
		},
		{
			path: ROUTES.IHR_DASHBOARD,
			requiredAuth: true,
			element: <IhrDashboard />,
		},
		{
			path: ROUTES.IHR_PAN,
			requiredAuth: true,
			element: <Pan />,
		},
		{
			path: ROUTES.IHR_PAN_MASK,
			requiredAuth: true,
			element: <MaskNumber />,
		},
		{
			path: ROUTES.IHR_QUESTIONS,
			requiredAuth: true,
			// element: <Questions />,
			element: <IhrQuestions />,
		},
		{
			path: ROUTES.IHR_PAN_QUESTIONS,
			requiredAuth: true,
			// element: <NonVerifiedUserQuestions />,
			element: <IhrQuestions />,
		},
		// {
		// 	path: ROUTES.IHR_JOURNEY,
		// 	requiredAuth: true,
		// 	element: <IhrQuestions />,
		// },
		// Details pages routes
		// {
		// 	path: ROUTES.AIR_AMBULANCE_DETAILS,
		// 	element: <AirAmbulanceDetails />,
		// },
		// Product routes
		{
			path: ROUTES.PRODUCT_LOGIN, // Dynamic login route
			element: <Login />,
		},
		{
			path: ROUTES.PRODUCT_DETAILS, // Dynamic details page route
			element: <DetailsPage />,
		},
		{
			path: ROUTES.SUPER_TOP_UP_PREMIUM,
			element: <PremiumEstimate />
		},
		{
			path: ROUTES.PRODUCT_QUESTIONNAIRE,
			requiredAuth: true,
			element: <UserDetailsForm />,
			fullScreen: true,
		},
		{
			path: ROUTES.PRODUCT_DASHBOARD,
			requiredAuth: true,
			element: <Order />,
			fullScreen: true,
		},
		{
			path: ROUTES.PRODUCT_ORDER,
			requiredAuth: true,
			element: <OrderRedirect />,
			fullScreen: true,
		},
		{
			path: ROUTES.PRODUCT_ORDER_STATUS,
			requiredAuth: true,
			element: <Order />,
			fullScreen: true,
		},
	];

const appRouter = createBrowserRouter([
	{
		path: "/",
		element: <><ScrollToTop /><App /></>,
		errorElement: <NotFound />,
		children: ROUTE_PROPERTIES,
	}
]);


root.render(
	<React.StrictMode>
		<Provider store={appStore}>
			<RouterProvider router={appRouter} />
		</Provider>
	</React.StrictMode>
);
