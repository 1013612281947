import React, { useState, useEffect } from 'react';
import premiumEstimateConfig from './premiumEstimateConfig';
import TickMark from "../../../assets/images/greencheck.svg";
import { useApi } from '../../../store/services/useApi';
import { validateAgeForAdults } from '../../../utils/helpers/questions';
import productsConfig from '../Common/UserDetailsForm/productsConfig';
import { useParams, useNavigate } from 'react-router-dom';

const PremiumEstimate = () => {

  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/dashboard');
  }, [navigate]);

  const [formData, setFormData] = useState({
    cover: premiumEstimateConfig.coverOptions[0],
    deductible: premiumEstimateConfig.deductibleOptions[0],
    family: [],
    kids: null,
    dob: ''
  });

  const [price, setPrice] = useState(null);
  const [productId, setProductId] = useState(null);
  const [pricingId, setPricingId] = useState(null);
  const [dobError, setDobError] = useState("Date of birth is required.");

  const { product_url } = useParams();
  const productConfig = productsConfig["super-topup"];

  const {
    data: pricing,
    error: pricingError,
    isLoading: pricingLoading,
    makeCall: fetchPricing
  } = useApi("POST", "/product_pricing/supertop");

  const coverMap = {
    '20 Lakhs': 2000000.00,
    '25 Lakhs': 2500000.00,
    '50 Lakhs': 5000000.00
  };

  const deductibleMap = {
    '5 Lakhs': 500000.00,
    '7 Lakhs': 700000.00,
    '10 Lakhs': 1000000.00
  };

  const kidsMap = {
    '01': 1,
    '02': 2
  };

  const handleSelect = (field, value) => {
    setFormData((prevData) => {
      let updatedData = { ...prevData };

      if (field === 'cover' || field === 'deductible') {
        // No deselection for cover and deductible
        if (prevData[field] !== value) {
          updatedData[field] = value;
        }
      } else {
        // Allow deselection for other fields
        updatedData[field] = prevData[field] === value ? null : value;
      }

      if (field === 'family') {
        if (prevData.family.includes(value)) {
          updatedData.family = prevData.family.filter(member => member !== value);
        } else {
          updatedData.family = [...prevData.family, value];
        }

        if (!updatedData.family.includes('Children')) {
          updatedData.kids = null;
        }
      }

      if (field === 'kids') {
        updatedData.kids = value;
      }

      if (field === 'dob') {
        if (value) {
          const isValidAge = validateAgeForAdults(value, productConfig);
          if (isValidAge) {
            setDobError("");
            updatedData.dob = value;
          } else {
            setDobError("Age should be greater than 18 years and less than 100 years");
          }
        } else {
          setDobError("Date of birth is required.");
        }
      }

      // Make API call after updating the form data
      if (updatedData.cover && updatedData.deductible && updatedData.dob) {
        const payload = {
          cover: coverMap[updatedData.cover] || updatedData.cover,
          deductible: deductibleMap[updatedData.deductible] || updatedData.deductible,
          dob: updatedData.dob,
          family: updatedData.family.length ? updatedData.family : [],
          kids: updatedData.kids?  kidsMap[updatedData.kids] : null
        };
        console.log("payload", payload);

        fetchPricing(payload);
      }

      return updatedData;
    });
  };

  useEffect(() => {
    console.log('Updated form data:', formData);
  }, [formData]);

  useEffect(() => {
    if (pricing && pricing.data && pricing.data.length) {
      const fetchedPrice = pricing.data[0].price;
      const fetchedProductId = pricing.data[0].product_id;
      const fetchedPricingId = pricing.data[0].id;

      setPrice(fetchedPrice);
      setProductId(fetchedProductId);
      setPricingId(fetchedPricingId);

      const premiumDetails = {
        sumInsured: coverMap[formData.cover],
        deductible: deductibleMap[formData.deductible],
        adults: 1 + (formData.family.length - (formData.kids ? 1 : 0)),
        numberOfChildren: formData.kids ? kidsMap[formData.kids] : 0,
        productId: fetchedProductId,
        pricingId: fetchedPricingId,
        price: fetchedPrice
      };

      // console.log("premium", premiumDetails);
      localStorage.setItem('premiumDetails', JSON.stringify(premiumDetails));
    }
  }, [pricing]);

  return (
    <div className="bg-white font-sans p-4 mt-4 rounded-md w-full mx-auto">
      <h2 className="w-full font-bold text-[#020064] text-xl lg:text-2xl text-left md:text-lg">
        Get the estimate of premium
      </h2>
      <p className="text-xs font-sans font-semibold md:text-sm text-[#020064] text-left mb-4 mt-2 lg:font-semibold">
        Understand the plan better by calculating your premium
      </p>

      {/* Cover Selection */}
      <div className="mb-4">
        <p className="font-semibold font-sans text-sm lg:text-xl mb-2 text-[#020064]">Select the cover</p>
        <p className="italic font-sans text-xs md:text-sm text-[#020064] text-left mb-4 mt-2 font-bold">
          Coverage is what your insurance pays for
        </p>
        <div className="grid grid-cols-3 gap-2 lg:gap-28 mt-6 mb-8 lg:px-20">
          {premiumEstimateConfig.coverOptions.map((cover) => (
            <button
              key={cover}
              onClick={() => handleSelect('cover', cover)}
              className={`p-4 border rounded-lg text-center font-bold lg:mx-22 ${
                formData.cover === cover ? 'bg-[#DDFFF3] text-[#066C42] shadow-lg' : 'border-black text-black'
              }`}
            >
              {cover}
            </button>
          ))}
        </div>
      </div>


      {/* Deductible Selection */}
      <div className="mb-4">
        <p className="font-semibold font-sans text-sm lg:text-xl mb-2 text-[#020064]">Preferred deductible amount</p>
        <p className="italic font-sans text-xs md:text-sm text-[#020064] text-left mb-4 mt-2 font-bold">
          The deductible is the amount you pay before your insurance takes over
        </p>
        <div className="grid grid-cols-3 gap-2 lg:gap-28 mt-6 mb-8 lg:px-20">
          {premiumEstimateConfig.deductibleOptions.map((amount) => (
            <button
              key={amount}
              onClick={() => handleSelect('deductible', amount)}
              className={`p-4 border rounded-lg text-center font-bold ${
                formData.deductible === amount ? 'bg-[#DDFFF3] text-[#066C42] shadow-lg' : 'border-black text-black'
              }`}
            >
              {amount}
            </button>
          ))}
        </div>
      </div>

      {/* Family Selection */}
      <div className="mb-4">
        <p className="font-semibold font-sans text-sm lg:text-xl mb-2 text-[#020064]">Select other family members you want to secure</p>
        <p className="italic font-sans text-xs md:text-sm text-[#020064] text-left mb-4 mt-2 font-bold">
          You can select multiple options
        </p>
        <div className="grid grid-cols-2 gap-2 lg:gap-28 mt-6 mb-8 lg:px-20">
          {premiumEstimateConfig.familyOptions.map((member) => (
            <button
              key={member}
              onClick={() => handleSelect('family', member)}
              className={`p-4 border rounded-lg text-center font-bold ${
                formData.family.includes(member) ? 'bg-[#DDFFF3] text-[#066C42] shadow-lg' : 'border-black text-black'
              }`}
            >
              {member}
            </button>
          ))}
        </div>
      </div>

      {/* Kids Selection */}
      {formData.family.includes('Children') && (
        <div className="mb-4">
          <p className="font-semibold font-sans text-sm lg:text-xl mb-2 text-[#020064]">How many kids you want to cover?</p>
          <div className="grid grid-cols-2 gap-2 lg:gap-28 mt-6 mb-8 lg:px-20">
            {premiumEstimateConfig.kidsOptions.map((kids) => (
              <button
                key={kids}
                onClick={() => handleSelect('kids', kids)}
                className={`p-4 border rounded-lg text-center font-bold ${
                  formData.kids === kids ? 'bg-[#DDFFF3] text-[#066C42] shadow-lg' : 'border-black text-black'
                }`}
              >
                {kids}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Date of Birth */}
      <div className="mb-8">
        <p className="font-semibold font-sans text-sm lg:text-xl mb-2 text-[#020064]">Date of birth</p>
        <p className="italic font-sans text-xs md:text-sm text-[#020064] text-left mb-4 mt-2 font-bold">
        Age impacts health, risks, and insurance needs in health reports.
        </p>
        <input
          type="date"
          value={formData.dob}
          onChange={(e) => handleSelect('dob', e.target.value)}
          className="p-2 border rounded-lg w-full"
        />
          {dobError && (
          <p className="text-red-600 text-sm mt-1">{dobError}</p>
          )}
      </div>

      {/* Quote Display */}
      <div className="mb-4">
        <p className="font-bold font-sans text-2xl lg:text-xl mb-2 text-[#020064]">Your Quote</p>
        <div className="grid grid-cols-1 gap-2 lg:gap-4 lg:px-20 mt-6 mb-6 text-sm text-[#020064]">
          <div
            className={`p-2 border rounded-lg text-center text-xs lg:text-sm font-bold flex items-center justify-center border-black ${
              price ? 'bg-[#DDFFF3]' : ''
            }`}
          >
            <div>
              {price ? (
                <>
                <div className="flex items-center">
                  <img src={TickMark} alt="Tick Mark" className="w-4 h-4 mr-2 mb-1" />
                  <div>
                    <p className="flex items-center">
                      Annual Premium
                    </p>
                    <p>Rs. {price} per year</p>
                  </div>
                </div>
                </>
              ) : (
                <>
                  <p>Annual Premium</p>
                  <p>Please Select Values</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <a
          href="/super-topup/login"
          className={`font-sans font-bold p-2 px-8 ${formData.dob && !dobError ? 'bg-[#F4CD2A] text-black' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
          aria-disabled={!formData.dob || dobError}
        >
          ENROLL NOW
        </a>
      </div>
    </div>
  );
};

export default PremiumEstimate;
