import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetHealthProductsQuery } from '../../../../store/services/healthproducts';
import STRINGS from '../../../../utils/constants/strings';

const HealthInsurance = ({ setValue, errors }) => {
  const { register, watch } = useFormContext();
  const { data, isLoading, isError } = useGetHealthProductsQuery();

  const insurers = Object.keys(data || {}).map((insurer) => ({
  value: insurer,
  label: insurer,
  }));

  const selectedInsurer = watch('health_insurance_insurer_name', '');

  const filteredProducts = selectedInsurer
  ? data?.[selectedInsurer] || []
  : [];

  const plans = filteredProducts.map((product) => ({
    value: product.id,
    label: product.product_name,
  }));


  // useEffect(() => {
  //   setValue('health_insurance_product_id', ''); // Reset the plan when the insurer changes
  // }, [selectedInsurer, setValue]);


  return (
    <>
      <div>
        <p className="text-xl font-bold lg:text-3xl text-blue-950 lg:mb-8 mb-5">
              We want to learn more about you
        </p>
        
      <div className="mb-5">
        <label className="font-bold text-xs lg:text-sm text-blue-950 mt-12 mb-12 lg:mb-2">
          Personal Health Insurer Name*
        </label>

        <select className='border p-4 w-full rounded-2xl' {...register('health_insurance_insurer_name', { required: 'Insurer name is required' })}>

            <option value="" disabled>Select Insurer Provider</option>
            {insurers.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          
        </select>
        {errors.insurer && <p>{errors.insurer.message}</p>}
      </div>

      <div className="mb-5">
        <label className="font-bold text-xs lg:text-sm text-blue-950 mt-12 mb-12 lg:mb-2">
          What's your Cumulative Sum Insured?*
        </label>
        <input className='border p-4 w-full rounded-2xl'
          type="text"
          {...register('health_insurance_sum_insured', { required: 'Sum assured is required' })}
        />
        {errors.sum_insured && <p>{errors.sum_insured.message}</p>}
      </div>

      
      <div className="mb-5">
        <label className="font-bold text-xs lg:text-sm text-blue-950 mt-12 mb-12 lg:mb-2">
          What's your Personal Health Insurance plan's name?
        </label>
        <select {...register('health_insurance_product_id', { required: 'Plan name is required' })} className='border p-4 w-full rounded-2xl' disabled={!selectedInsurer}>
              <option value="" disabled>Select Plan</option>
            {plans.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
        {errors.plan && <p>{errors.plan.message}</p>}
      </div>
      </div>

    </>
  );
};

export default HealthInsurance;
